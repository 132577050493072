<template>
  <Modal
    ref="modal"
    :title="`Update ${roleTitle}`"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseSelect
        v-model="usrId"
        :options="users"
        :title="roleTitle"
        name="usrId"
        bind-by="id"
        label="name"
        :loading="loading"
        :disabled="loading"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to set the team for a Project.
 * Emits the 'team-update' even if successful.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import bookingAPI from '@/api/booking'
import userAPI from '@/api/user'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      loading: false,
      roleName: '',
      roleTitle: '',
      usrId: null,
      id: '',
      users: [],
    }
  },
  beforeMount () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true
      userAPI.getUsers().then(users => {
        this.users = users.data
        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
    reset () {
      this.error = ''
      this.id = ''
      this.roleName = ''
      this.roleTitle = ''
      this.usrId = null
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (name, title, update) {
      this.reset()
      this.id = update.id
      this.roleName = name
      this.roleTitle = title
      this.usrId = update[name + '_id']
      this.$refs.modal.show()
    },
    save () {
      bookingAPI.updateCrRole(this.id, this.roleName, this.usrId).then(resp => {
        this.$emit('update')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
