<template>
  <div class="bg-white p-3">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="text-primary font-weight-bold">
        RECIPIENTS
      </h6>
      <button
        class="edit-icon float-right btn btn-primary btn-xs mb-2"
        @click.prevent="addingRecipient = true"
      >
        New
      </button>
    </div>
    <div
      v-for="(r, i) in value"
      :key="r"
      class="p-1 text-gray-600"
    >
      {{ r }}
      <span
        class="float-right pointer"
        @click.prevent="deleteRecipient(i)"
      >
        <TrashIcon title="" />
      </span>
    </div>
    <BaseSelect
      v-show="addingRecipient"
      v-model="recipient"
      :options="users"
      name="recipient"
      bind-by="email"
      label="name"
      tag-placeholder="Press enter to add email address"
      placeholder="Select user or enter email address"
      taggable
      class="mb-0"
      @input="addRecipient"
      @tag="addRecipient"
    />
  </div>
</template>

<script>
import TrashIcon from 'vue-material-design-icons/TrashCanOutline.vue'

export default {
  components: {
    TrashIcon,
  },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    users: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      recipient: '',
      addingRecipient: false,
    }
  },
  methods: {
    addRecipient (email) {
      if (!this.value) {
        this.$emit('input', [email])
      } else if (!this.value.includes(email)) {
        const recipients = [...this.value]
        recipients.push(email)
        this.$emit('input', recipients)
      }
      this.recipient = ''
      this.addingRecipient = false
    },
    deleteRecipient (index) {
      const recipients = [...this.value]
      recipients.splice(index, 1)
      this.$emit('input', recipients)
    },
  },
}
</script>
