<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0;"
  >
    <ListTemplate
      ref="listTemplate"
      api-url="crs"
      :per-page="20"
      :fields="fields"
      :update-action="false"
      :default-filters="defaultFilters"
      :append-params="appendParams"
      default-sort="start_date,number,id"
      title="CR Dashboard"
      csv-download="crdata.csv"
    >
      <template #list-header>
        <div style="width: 30%;">
          <h3 class="mb-2">
            CR Dashboard
          </h3>
          <BaseInput
            v-model="search"
            name="search"
            title=""
            class="mb-0"
            placeholder="Filter by number or description"
            trim
          >
            <template #prepend>
              <SearchIcon />
            </template>
          </BaseInput>
        </div>
      </template>
      <template #status="props">
        <div style="padding: 6px 10px;">
          <span :class="{ 'text-nowrap': true, 'text-danger': isUnsuccessful(props.rowData.status) }">
            {{ props.rowData.status }}
          </span>
          <div
            class="crdash-edit-icon"
            @click.prevent="editStatus(props.rowData)"
          >
            <EditIcon title="" />
          </div>
        </div>
      </template>
      <template #night_engineer_name="props">
        <div
          class="user-select-none"
          style="padding: 6px 10px;"
        >
          <span class="text-nowrap">
            {{ props.rowData.night_engineer_name }}
          </span>
          <div
            class="crdash-edit-icon"
            @click.prevent="editRole('night_engineer', 'Night Engineer', props.rowData)"
          >
            <EditIcon title="" />
          </div>
        </div>
      </template>
      <template #info="props">
        <div
          style="font-size: 1.2em; padding-top: 4px;"
          class="text-gray-600 clickable"
          @click.prevent="showInfoModal(props.rowData.id)"
        >
          <InfoIcon title="Detail" />
        </div>
      </template>
    </ListTemplate>
    <CRStatusUpdate
      ref="statusUpdate"
      @status-update="refreshTable"
    />
    <CRRoleUpdate
      ref="roleUpdate"
      @update="refreshTable"
    />
    <CRDashboardInfoModal
      ref="infoModal"
      @update="refreshTable"
    />
  </div>
</template>

<script>
import ListTemplate from '@/components/ListTemplate'
import CRStatusUpdate from './CRStatusUpdate'
import CRRoleUpdate from './CRRoleUpdate'
import CRDashboardInfoModal from './CRDashboardInfoModal'
import EditIcon from 'vue-material-design-icons/PencilBox.vue'
import InfoIcon from 'vue-material-design-icons/InformationOutline.vue'
import SearchIcon from 'bootstrap-icons/icons/search.svg?inline'
import userAPI from '@/api/user'
import { mapState } from 'vuex'
import formatDateTime from '@/filters/formatDateTime'

export default {
  components: {
    EditIcon,
    InfoIcon,
    SearchIcon,
    CRStatusUpdate,
    CRRoleUpdate,
    CRDashboardInfoModal,
    ListTemplate,
  },
  data () {
    return {
      search: '',
    }
  },
  computed: {
    appendParams () { return this.search ? { search: this.search } : {} },
    currentTeams () {
      return this.currentUser.teams.filter(t => t.enable_cr_dash).map(t => t.team_name)
    },
    fields () {
      return [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'project_id',
          title: 'Project ID',
          visible: true,
          filterType: Number,
          linkTo: this.projectLink,
        },
        {
          name: 'team_name',
          title: 'Team',
          filterType: String,
          filterOptions: this.currentTeams,
          visible: this.currentTeams.length > 1,
        },
        {
          name: 'number',
          title: 'Number',
          filterType: String,
          filterField: 'number_like',
          visible: true,
          dataClass: 'text-nowrap',
        },
        {
          name: 'description',
          title: 'Description',
          filterType: String,
          filterField: 'description_like',
          visible: true,
        },
        {
          name: 'status',
          title: 'Status',
          filterType: String,
          filterOptions: [
            'Scheduled for Implementation',
            'Successfully Completed',
            'Failed',
            'Rollback',
            'Partially Implemented',
            'Re-scheduled',
            'Rejected',
            'Cancelled',
          ],
          visible: true,
          dataClass: 'position-relative p-0',
        },
        {
          name: 'outcome',
          title: 'Outcome',
          visible: false,
        },
        {
          name: 'outcome_details',
          title: 'Outcome Details',
          visible: false,
        },
        {
          name: 'start_date',
          title: 'Start Date',
          filterType: Date,
          visible: true,
          dataClass: 'text-nowrap',
          formatter: this.formatDate,
        },
        {
          name: 'end_date',
          title: 'End Date',
          filterType: Date,
          visible: false,
          dataClass: 'text-nowrap',
          formatter: this.formatDate,
        },
        {
          name: 'presenter_name',
          title: 'Presenter',
          filterType: String,
          filterField: 'presenter_name_like',
          visible: true,
        },
        {
          name: 'project_manager_name',
          title: 'Project Manager',
          filterType: String,
          filterOptions: userAPI.getUserNames,
          visible: true,
        },
        {
          name: 'night_engineer_name',
          title: 'Night Engineer',
          filterType: String,
          filterOptions: userAPI.getUserNames,
          visible: true,
          dataClass: 'position-relative p-0',
        },
        {
          name: 'cr_type',
          title: 'Type',
          filterType: String,
          // TODO: use api call to get options
          filterOptions: [
            'Normal CR',
            'Standard CR',
            'Model CR',
            'Expedited CR',
          ],
          visible: true,
          dataClass: 'text-nowrap',
        },
        {
          name: 'info',
          title: '',
          titleClass: 'center aligned',
          dataClass: 'p-0',
        },
        {
          name: 'actions',
          title: '',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ]
    },
    defaultFilters () {
      return [
        {
          // by default only show crs starting from today or later
          params: { status: 'Scheduled for Implementation' },
          fields: ['number_like', 'status', 'search'],
        },
      ]
    },
    ...mapState('user', ['currentUser']),
  },
  methods: {
    isUnsuccessful (status) {
      return !['Scheduled for Implementation', 'Successfully Completed'].includes(status)
    },
    editStatus (rowData) {
      this.$refs.statusUpdate.edit(rowData)
    },
    editRole (name, title, rowData) {
      this.$refs.roleUpdate.edit(name, title, rowData)
    },
    showInfoModal (id) {
      this.$refs.infoModal.show(id)
    },
    refreshTable () {
      this.$refs.listTemplate.refresh()
    },
    formatDate (d) {
      return formatDateTime(d, 'DD/MM/YYYY')
    },
    projectLink (cr) {
      if (cr.project_id) {
        return { name: 'show_project_info', params: { projectId: cr.project_id } }
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
div ::v-deep .table tbody {
  .crdash-edit-icon {
    visibility: hidden;
    color: $gray-600;
    font-size: 1.4rem;
    bottom: 0;
    right: 0;
    height: 100%;
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    background-color:rgb(244, 244, 244);

    &:hover {
      cursor: pointer;
    }
  }
  tr:hover {
    background-color:rgb(244, 244, 244);
    .crdash-edit-icon {
      visibility: visible;
    }
  }
}
</style>
