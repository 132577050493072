<template>
  <div>
    <BaseSelect
      v-model="innerStatus"
      :options="statusList"
      title="Status"
      name="team"
      placeholder="Select Status"
      required
    />
    <BaseSelect
      v-if="!disableOutcome"
      v-model="innerOutcome"
      :options="outcomeList"
      title="Outcome"
      name="outcome"
      placeholder="Select Outcome"
      required
    />
    <BaseTextarea
      v-if="!disableOutcome"
      v-model="innerOutcomeDetails"
      :rows="4"
      title="Outcome Details"
      name="outcome_details"
      required
      trim
    />
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: '',
    },
    outcome: {
      type: String,
      default: '',
    },
    outcomeDetails: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      statusList: [
        'Scheduled for Implementation',
        'Successfully Completed',
        'Failed',
        'Rollback',
        'Partially Implemented',
        'Re-scheduled',
        'Rejected',
        'Cancelled',
      ],
      outcomeList: [
        'Scenario not as expected',
        'Incorrect project inputs',
        'Missing configuration (L2)',
        'Network incident',
        'Embargo',
        'Duplicated/Back-up',
        'Not approved in time',
        'Product limitation or bug',
        'Under investigation',
        'Conflicting change',
        'No longer required',
        'Customer not ready',
      ],
    }
  },
  computed: {
    disableOutcome () {
      return this.disabledOutcome(this.status)
    },
    innerStatus: {
      get () { return this.status },
      set (s) {
        this.$emit('update:status', s)
        if (this.disabledOutcome(s)) {
          this.$emit('update:outcome', null)
          this.$emit('update:outcome-details', null)
        }
      },
    },
    innerOutcome: {
      get () { return this.outcome },
      set (o) {
        this.$emit('update:outcome', o)
      },
    },
    innerOutcomeDetails: {
      get () { return this.outcomeDetails },
      set (o) {
        this.$emit('update:outcome-details', o)
      },
    },
  },
  methods: {
    disabledOutcome (s) {
      return s === 'Scheduled for Implementation' || s === 'Successfully Completed'
    },
  },
}
</script>
