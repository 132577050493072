<template>
  <div class="bg-white p-3">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="text-primary font-weight-bold">
        ACTIVITIES
      </h6>
      <button
        class="edit-icon float-right btn btn-primary btn-xs"
        @click.prevent="addActivity"
      >
        New
      </button>
    </div>
    <table
      v-if="hasActivities"
      class="w-100"
    >
      <tr>
        <th>Time</th>
        <th>Type</th>
        <th>Description</th>
        <th v-if="hasAttachment">
          Attachment
        </th>
        <th />
      </tr>
      <tr
        v-for="a in value"
        :key="a.id"
      >
        <td class="text-nowrap">
          {{ formatDateTime24(a.time) }}
        </td>
        <td>{{ a.type }}</td>
        <td>
          <span style="white-space: pre-wrap;">{{ a.description }}</span>
        </td>
        <td v-if="hasAttachment">
          <a :href="attachmentDownloadLink(a.id)">
            {{ a.attachment_name }}
          </a>
        </td>
        <td>
          <span
            class="edit-icon"
            @click.prevent="confirmDeleteActivity(a.id)"
          >
            <TrashIcon title="" />
          </span>
        </td>
      </tr>
    </table>
    <FieldError
      v-model="error"
      class="my-3"
    />
    <CRActivityCreate
      ref="activityCreate"
      :exclude-files="attachmentFilenames"
      @update="refresh"
    />
    <ConfirmDialog
      ref="dialog"
      @confirmed="deleteActivity"
    />
  </div>
</template>

<script>
import FieldError from '@/components/FieldError'
import ConfirmDialog from '@/components/ConfirmDialog'
import CRActivityCreate from './CRActivityCreate'
import TrashIcon from 'vue-material-design-icons/TrashCanOutline.vue'
import bookingAPI from '@/api/booking'
import moment from 'moment'

export default {
  components: {
    TrashIcon,
    CRActivityCreate,
    ConfirmDialog,
    FieldError,
  },
  props: {
    crId: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      error: '',
    }
  },
  computed: {
    hasAttachment () {
      return this.value && this.value.findIndex(a => a.attachment_id) >= 0
    },
    hasActivities () {
      return this.value && this.value.length > 0
    },
    attachmentFilenames () {
      if (!this.hasActivities) {
        return []
      }
      return this.value.map(a => a.attachment_name).filter(fn => !!fn)
    },
  },
  methods: {
    addActivity () {
      this.$refs.activityCreate.edit(this.crId)
    },
    confirmDeleteActivity (id) {
      this.$refs.dialog.confirm(
        'Confirm Delete Activity',
        'Are you sure you want to delete this activity?',
        id,
      )
    },
    deleteActivity (id) {
      bookingAPI.deleteCrActivity(this.crId, id).then(resp => {
        this.refresh()
      }).catch(error => {
        this.error = error.message
      })
    },
    getActivities () {
      bookingAPI.getCRActivities(this.crId).then(resp => {
        this.$emit('input', resp.data)
      }).catch(error => {
        this.error = error.message
      })
    },
    refresh () {
      this.getActivities()
    },
    attachmentDownloadLink (id) {
      return process.env.VUE_APP_API_URL + bookingAPI.crAttachmentDownloadURL(id)
    },
    formatDateTime24 (value, fmt = 'Do MMM YYYY HH:mm') {
      if (value) {
        const m = moment.utc(String(value))
        if (m.isValid) {
          return m.format(fmt)
        }
      }
    },
  },
}
</script>
