<template>
  <BaseFormCard
    heading="Create CR"
    submit-title="Create CR"
    :submit-promise="submit"
  >
    <BaseSelect
      v-model="cr.project_id"
      title="Project ID"
      name="project_id"
      bind-by="id"
      :custom-label="projectLabel"
      :async-find="searchCRProjects"
      required
    />
    <BaseInput
      v-model="cr.number"
      title="CR Number"
      name="number"
      type="text"
      placeholder="CR Number"
      required
      trim
    />
    <BaseInput
      v-model="cr.description"
      title="CR Description"
      name="description"
      type="text"
      placeholder="CR Description"
      required
      trim
    />
    <BaseSelect
      v-model="cr.cr_type_id"
      :options="bookingSubtypes"
      :loading="loading"
      title="CR Type"
      name="cr_type_id"
      bind-by="id"
      label="name"
      required
    />
    <BaseTextarea
      v-model="cr.executive_summary"
      :rows="4"
      title="Executive Summary"
      name="executive_summary"
      trim
    />
    <CRStatusUpdateFields
      :status.sync="cr.status"
      :outcome.sync="cr.outcome"
      :outcome-details.sync="cr.outcome_details"
    />
    <BaseSelect
      v-model="cr.presenter_id"
      :options="users"
      title="Presenter"
      name="presenter_id"
      bind-by="id"
      label="name"
    />
    <BaseSelect
      v-model="cr.night_engineer_id"
      :options="users"
      title="Night Engineer"
      name="night_engineer_id"
      bind-by="id"
      label="name"
    />
    <BaseSelect
      v-model="cr.night_engineer_backup_id"
      :options="users"
      title="Night Engineer Backup"
      name="night_engineer_backup_id"
      bind-by="id"
      label="name"
    />
    <BaseSelect
      v-model="cr.project_manager_id"
      :options="users"
      title="Project Manager"
      name="project_manager_id"
      bind-by="id"
      label="name"
    />
    <BaseInputDateTime
      v-model="cr.start_date"
      :max="cr.end_date"
      title="CR Start Date"
      name="start_date"
      required
    />
    <BaseInputDateTime
      v-model="cr.end_date"
      :min="cr.start_date"
      title="CR End Date"
      name="end_date"
      required
    />
    <BaseTextarea
      v-model="cr.impacted_device"
      :rows="4"
      title="Impacted Device"
      name="impacted_device"
      required
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to create or update a User.
 */
import FieldError from '@/components/FieldError'
import CRStatusUpdateFields from './CRStatusUpdateFields'
import userAPI from '@/api/user'
import groupAPI from '@/api/group'
import bookingAPI from '@/api/booking'

export default {
  components: {
    FieldError,
    CRStatusUpdateFields,
  },
  props: {
    /**
     * User to edit. If not provided, create a new user.
     */
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      cr: {
        project_id: '',
        number: '',
        description: '',
        booking_subtype_id: '',
        status: '',
        outcome: '',
        outcome_details: '',
        presenter_id: null,
        night_engineer_id: null,
        night_engineer_backup_id: null,
        project_manager_id: null,
        start_date: '',
        end_date: '',
        impacted_device: '',
      },
      users: [],
      bookingSubtypes: [],
      loading: false,
      error: '',
    }
  },
  beforeMount () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true
      Promise.all([
        userAPI.getUsers(),
        groupAPI.getGroups(),
        bookingAPI.getAllBookingSubtypes(),
      ]).then(([users, groups, subtypes]) => {
        this.users = users.data
        // TODO remove dependancy on 'IP Networks' & 'Implementation'
        const ipnGroup = groups.data.find(g => g.name === 'IP Networks')
        if (ipnGroup && ipnGroup.id) {
          this.bookingSubtypes = subtypes.data.filter(st => {
            return !st.child && st.booking_type.project_type_id === ipnGroup.id && st.booking_type.name === 'Implementation'
          })
        }
        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
    async searchCRProjects (search) {
      const resp = await bookingAPI.searchCrProjects(search.trim())
      return resp.data
    },
    projectLabel (p) {
      return p.id ? `${p.id} - ${p.name}` : p.name
    },
    submit () {
      bookingAPI.createCR(this.cr).then(users => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
