<template>
  <Modal
    ref="modal"
    title="Update CR Status"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <CRStatusUpdateFields
        :status.sync="update.status"
        :outcome.sync="update.outcome"
        :outcome-details.sync="update.outcome_details"
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to set the team for a Project.
 * Emits the 'team-update' even if successful.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import CRStatusUpdateFields from './CRStatusUpdateFields'
import bookingAPI from '@/api/booking'

export default {
  components: {
    Modal,
    FieldError,
    CRStatusUpdateFields,
  },
  data () {
    return {
      error: '',
      update: {
        id: '',
        status: '',
        outcome: '',
        outcome_details: '',
      },
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.update = {
        id: '',
        status: '',
        outcome: '',
        outcome_details: '',
      }
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (update) {
      this.reset()
      this.update = { ...update }
      this.$refs.modal.show()
    },
    save () {
      bookingAPI.updateCRstatus(this.update.id, this.update).then(resp => {
        this.$emit('status-update', this.update)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
