<template>
  <Modal
    ref="modal"
    title="Create CR Activity"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseInputDateTime
        v-model="activity.time"
        title="Time"
        name="time"
        required
      />
      <BaseSelect
        v-model="activity.type"
        :options="types"
        title="Type"
        name="type"
        required
      />
      <BaseTextarea
        v-model="activity.description"
        :rows="4"
        title="Description"
        name="description"
        required
        trim
      />
      <BaseInputFile
        v-model="file"
        :max-size="maxFileSize"
        :exclude-files="excludeFiles"
        exclude-message="The Attachment has already been uploaded in another activity"
        title="Attachment"
        name="file"
        type="file"
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import bookingAPI from '@/api/booking'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  components: {
    Modal,
    FieldError,
  },
  props: {
    excludeFiles: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      error: '',
      activity: { time: null, type: '', description: '' },
      types: [
        'Activity',
        'Pre-Change',
        'Post-Change',
        'Login',
        'Logoff',
        'Write-Access',
      ],
      id: '',
      file: null,
    }
  },
  computed: {
    maxFileSize () {
      return this.currentUser.max_attachment_size_mb * 1024
    },
    ...mapState('user', ['currentUser']),
  },
  methods: {
    reset () {
      this.error = ''
      this.id = ''
      this.file = null
      this.activity = {
        // time without timezone (JSON uses UTC)
        time: moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
        type: '',
        description: '',
      }
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (id) {
      this.reset()
      this.id = id
      this.$refs.modal.show()
    },
    save () {
      bookingAPI.createCrActivity(this.id, this.activity).then(resp => {
        if (this.file) {
          return bookingAPI.uploadCrAttachment(resp.data.id, this.file)
        }
        return Promise.resolve()
      }).then(resp => {
        this.$emit('update')
        this.cancel()
      }).catch(error => {
        this.error = error.message
        this.$emit('update')
      })
    },
  },
}
</script>
