<template>
  <Modal
    ref="modal"
    title="Update CR"
    extra-large
  >
    <div>
      <ValidationObserver
        v-slot="{ handleSubmit, changed }"
        ref="observer"
      >
        <form @submit.prevent="handleSubmit(updateCR)">
          <div class="mt-2">
            <span
              class="rounded-lg text-light p-2 bg-brand"
            >
              {{ cr.number }}
            </span>
          </div>
          <h4 class="mt-3 font-weight-bold text-brand">
            Project {{ cr.project_id }} | {{ cr.project_name }}
          </h4>
          <h5 class="text-brand">
            {{ cr.description }}
          </h5>
          <h6 class="text-brand">
            {{ cr.cr_type }}
          </h6>
          <div class="bg-white p-3 mt-2">
            <BaseTextarea
              v-model="cr.executive_summary"
              name="executive_summary"
              title="Executive Summary"
              trim
            />
            <BaseTextarea
              v-model="cr.impacted_device"
              name="impacted_device"
              title="Impacted Device"
              trim
            />
            <CRStatusUpdateFields
              :status.sync="cr.status"
              :outcome.sync="cr.outcome"
              :outcome-details.sync="cr.outcome_details"
            />
            <div class="d-flex align-items-center">
              <BaseInputDateTime
                v-model="cr.start_date"
                :max="cr.end_date"
                title="Start"
                name="start_date"
                required
                class="mr-2"
              />
              <BaseInputDateTime
                v-model="cr.end_date"
                :min="cr.start_date"
                title="End"
                name="end_date"
                required
                class=""
              />
            </div>
            <div
              v-if="showOutcome"
              class="d-flex align-items-top"
            >
              <BaseInputDateTime
                v-model="cr.next_update_time"
                title="Next Update"
                name="next_update_time"
                class="mr-2"
                required
              />
            </div>
            <BaseTextarea
              v-if="showOutcome"
              v-model="cr.next_update_details"
              name="next_update_details"
              title="Next Update Details"
              class="flex-grow-1"
              required
              trim
            />
          </div>
          <div class="bg-white p-3 mt-2">
            <h6 class="text-primary font-weight-bold">
              CONTACTS
            </h6>
            <div class="row">
              <div class="col-md-6">
                <BaseSelect
                  v-model="cr.presenter_id"
                  :options="users"
                  title="Presenter"
                  name="presenter_id"
                  bind-by="id"
                  label="name"
                  required
                />
              </div>
              <div class="col-md-6">
                <BaseSelect
                  v-model="cr.project_manager_id"
                  :options="users"
                  title="Project Manager"
                  name="project_manager_id"
                  bind-by="id"
                  label="name"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <BaseSelect
                  v-model="cr.night_engineer_id"
                  :options="users"
                  title="Night Engineer"
                  name="night_engineer_id"
                  bind-by="id"
                  label="name"
                  required
                />
              </div>
              <div class="col-md-6">
                <BaseSelect
                  v-model="cr.night_engineer_backup_id"
                  :options="users"
                  title="Night Engineer Backup"
                  name="night_engineer_backup_id"
                  bind-by="id"
                  label="name"
                  required
                />
              </div>
            </div>
          </div>
          <CRUpdateActivities
            v-model="cr.activities"
            :cr-id="cr.id"
            class="mt-2"
          />
          <ValidationProvider>
            <CRUpdateRecipients
              v-model="cr.recipients"
              :users="users"
              class="mt-2"
            />
          </ValidationProvider>
          <FieldError
            v-model="error"
            class="mt-3"
          />
          <div class="mt-3">
            <BaseFormButton
              v-if="changed"
              type="submit"
              :is-loading="saving"
            >
              Save Changes
            </BaseFormButton>
            <BaseFormButton
              v-else
              type="submit"
              :is-loading="sendingReport"
              :disabled="!canSendReport || sentReport"
              @click.prevent="sendPostCrReport"
            >
              {{ sentReport ? 'Report Sent' : 'Send Report' }}
            </BaseFormButton>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CRStatusUpdateFields from './CRStatusUpdateFields'
import CRUpdateActivities from './CRUpdateActivities'
import CRUpdateRecipients from './CRUpdateRecipients'
import bookingAPI from '@/api/booking'
import userAPI from '@/api/user'

export default {
  components: {
    CRStatusUpdateFields,
    CRUpdateActivities,
    CRUpdateRecipients,
    ValidationObserver,
    ValidationProvider,
    FieldError,
    Modal,
  },
  data () {
    return {
      error: '',
      loading: false,
      cr: {},
      sendingReport: false,
      sentReport: false,
      users: [],
    }
  },
  computed: {
    canSendReport () {
      return this.cr.recipients && this.cr.recipients.length > 0 &&
      this.cr.executive_summary &&
      this.cr.status !== 'Scheduled for Implementation' &&
      (!this.showOutcome || (this.cr.next_update_time && this.cr.next_update_details))
    },
    showOutcome () {
      return this.cr.status !== 'Scheduled for Implementation' && this.cr.status !== 'Successfully Completed'
    },
  },
  beforeMount () {
    this.getUsers()
  },
  methods: {
    show (id) {
      this.cr = {}
      this.error = ''
      this.loading = false
      this.sendingReport = false
      this.sentReport = false
      this.saving = false
      this.getData(id)
      this.$refs.modal.show()
    },
    getData (id) {
      this.loading = true
      bookingAPI.getCRData(id).then(resp => {
        if (!resp.data.recipients) {
          resp.data.recipients = []
        }
        this.cr = resp.data
        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
    getUsers () {
      this.loading = true
      userAPI.getUsers().then(users => {
        this.users = users.data
        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
    refresh () {
      this.getData(this.cr.id)
      this.$emit('update')
    },
    sendPostCrReport () {
      this.error = ''
      this.sendingReport = true
      bookingAPI.sendPostCrReport(this.cr.id).then(resp => {
        this.sendingReport = false
        this.sentReport = true
      }).catch(error => {
        this.sendingReport = false
        this.error = error.message
      })
    },
    updateCR () {
      this.error = ''
      this.saving = true
      // clear next update records
      if (!this.showOutcome) {
        this.cr.next_update_time = null
        this.cr.next_update_details = null
      }
      bookingAPI.updateCR(this.cr).then(resp => {
        this.$refs.observer.reset()
        this.saving = false
      }).catch(error => {
        this.error = error.message
        this.saving = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal ::v-deep .modal-content {
  background-color: #f5f5f5;
}
</style>
